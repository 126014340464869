import { App, defineComponent, h, Component } from 'vue';

import HeaderView from '@/components/HeaderView.vue';
import FooterView from '@/components/FooterView.vue';
import SeachArea from '@/components/SeachArea.vue';
import ClassifyPart from '@/components/ClassifyPart.vue';
import TagView from '@/components/TagView.vue';

// 顶部栏
const Header = defineComponent({
	name: 'HeaderView', //名字必须要给
	setup() {
		const depop = {}  //这是默认的样式
		return () => h(HeaderView, { ...depop })  //api的h 创建一个虚拟节点，具体配置可以查看官方文档
	}
});

// 底部栏
const Footer = defineComponent({
	name: 'FooterView', //名字必须要给
	setup() {
		const depop = {}  //这是默认的样式
		return () => h(FooterView, { ...depop })  //api的h 创建一个虚拟节点，具体配置可以查看官方文档
	}
});

// 搜索
const Seach = defineComponent({
	name: "SeachArea",
	setup() {
		const depop = {}  //这是默认的样式
		return () => h(SeachArea, { ...depop })  //api的h 创建一个虚拟节点，具体配置可以查看官方文档
	}
});

// 分类栏
const Classify = defineComponent({
	name: "ClassifyPart",
	setup() {
		const depop = {}  //这是默认的样式
		return () => h(ClassifyPart, { ...depop })  //api的h 创建一个虚拟节点，具体配置可以查看官方文档
	}
});

// 标签
const Tag = defineComponent({
	name: "TagView",
	setup() {
		const depop = {}  //这是默认的样式
		return () => h(TagView, { ...depop })  //api的h 创建一个虚拟节点，具体配置可以查看官方文档
	}
})

const ComponentArr = [Header, Footer, Seach, Classify, Tag];

const arr: Component[] = [];

ComponentArr.forEach((v: any) => {
	arr.push(v);
})

export default {
	install(app: App) {  //将创建的组件导出时，install是必不可少的
		arr.forEach((item) => {
			app.component(String(item.name), item)
		})
		return app;
	}
}