import { createStore } from 'vuex'

export default createStore({
	state: {
		is_phone: false,
		storeInfo: "",
	},
	getters: {
	},
	mutations: {
		// 设置当前设备类型
		setWebSiteType(state, params: any) {
			state.is_phone = params;
		},
		// 设置公共字段
		setStoreInfo(state, params: any) {
			state.storeInfo = params;
		}
	},
	actions: {
	},
	modules: {
	}
})
