import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 全局挂载请求
import api from './api/api';
// 公共样式
import './assets/css/item.css';
import './assets/css/togyUI.css';
// 引入动画库
import "animate.css"
// 这块一定要加,否者会有部分动画无法执行
import 'animate.css/animate.compat.css'


// 自定义全局组件
import Public from './components/Components';

// element组件
import installElementPlus from './plugins/element';
import './styles/element/index.scss';
// vant组件
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/css/animation.less';

const app = createApp(App)

installElementPlus(app)
app.config.globalProperties.$api = api
app.use(store).use(router).use(Public).use(Vant).mount('#app')