import config from "./config";
import axios from "axios";
import { ElLoading } from 'element-plus';
import { ref } from "vue";

const token = ref < any > ();
const loading = ref < any > ();

// 调用接口封装
const request = (params: any) => {
    if (!params.method) {
        params.method = config.method;
    }

    if (!params.url) {
        console.log("未配置请求接口路径");
        return false
    }
    params.method = params.method.toLocaleUpperCase(); // 请求类型
    params.url = config.request_host + params.url;
    if (params.isLoading) {
        loading.value = ElLoading.service({
            lock: true,
            text: params.isLoading,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }
    if (params.method == 'GET') {
        params.params = params.data || {}
    }
    axios(params).then(res => {
        if (params.isLoading) {
            loading.value.close();
        }
        if (res.data.code == 200) {
            params.success && typeof params.success === 'function' && params.success(res.data);
        } else {
            params.fail && typeof params.fail === 'function' && params.fail(res.data);
        }
    }).catch(res => {
        if (params.isLoading) {
            loading.value.close();
        }
        params.fail && typeof params.fail === 'function' && params.fail(res);
    });
}

const tokenRequest = (params: any) => {
    token.value = localStorage.getItem('token');
    if (!token.value) {
        console.log("未获取到token,请检查登录或刷新token部分的代码");
        return false
    }
    params.headers = {
        // 'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/json',
        "Authorization": token.value,
    };
    request(params);
}

export default {
    request: request,
    tokenRequest: tokenRequest,
}
